import { lazy } from "react";
const BusinessListing = lazy(() => import("../Container"));
const FormIndex = lazy(() => import("../components/LeadFormSteps/FormIndex"));
export const routePath = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: BusinessListing
    },
    {
        name: "General Business",
        path: "/business/generalbusiness",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Accounting",
        path: "/business/accounting",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Business Administration",
        path: "/business/businessadministration",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Business Analytics",
        path: "/business/businessanalytics",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Communications",
        path: "/business/communications",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Economics",
        path: "/business/economics",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Finance",
        path: "/business/finance",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Human Resources",
        path: "/business/humanresources",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Management",
        path: "/business/management",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Digital Marketing",
        path: "/business/digitalmarketing",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Project Management",
        path: "/business/projectmanagement",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Sports Management",
        path: "/business/sportsmanagement",
        exact: true,
        component: BusinessListing
    },
    {
        name: "Healthcare Administration",
        path: "/business/healthcareadministration",
        exact: true,
        component: BusinessListing
    },  
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
     {
        name: "Page Not Found",
        path: "*",
        exact: true,
        component: BusinessListing
    },




    // {
    //     name: "Business",
    //     path: "/business/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
    // {
    //     name: "Criminal Justice",
    //     path: "/criminal-justice/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
    // {
    //     name: "Psychology",
    //     path: "/psychology/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
    // {
    //     name: "Computers & IT",
    //     path: "/computers-&-technology/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
    // {
    //     name: "Healthcare",
    //     path: "/health,-nursing,-medicine/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
    // {
    //     name: "Education & Teaching",
    //     path: "/education-&-teaching/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
    // {
    //     name: "Vocational Training",
    //     path: "/vocational-training/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
    // {
    //     name: "Liberal Arts",
    //     path: "/art-&-architecture/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
    // {
    //     name: "General",
    //     path: "/general/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
    // {
    //     name: "Nursing",
    //     path: "/nursing/:id?",
    //     exact: true,
    //     component: BusinessListing
    // },
]

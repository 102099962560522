import React, { Suspense } from 'react';
import "./App.scss"
import { routePath } from './common/routes';
import { Route, Routes } from 'react-router-dom';
import { WebContextProvider } from './contextProvider/webContextProvider';
import PageLoader from './components/PageLoader';
import GoogleTagManager from './helper/GoogleTagManager';

export const App =() => {
 
  return (
    <React.Fragment>
       <GoogleTagManager/>
      <WebContextProvider>
      <Routes>
        {routePath.map((route) => {
          return <Route
            key={route.path}
            path={route.path}
            // exact={route.exact}
            element={
              <Suspense fallback={<PageLoader />}>
                <route.component />
              </Suspense>
            }
            // name={route.name}
          />
        })}
      </Routes>
      </WebContextProvider>
    </React.Fragment>

  )
}
